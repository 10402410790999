import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="wishlist-32_svg__a" d="M15.984 27.234a.665.665 0 0 1-.4-.134c-11.232-8.488-12.1-11.66-12.1-15.214a6.873 6.873 0 0 1 6.581-7.12 6.568 6.568 0 0 1 5.948 3.643 6.429 6.429 0 0 1 5.923-3.643 6.873 6.873 0 0 1 6.58 7.12c0 3.423-.895 6.718-12.14 15.217a.653.653 0 0 1-.4.132zM10.065 6.086a5.557 5.557 0 0 0-5.263 5.8c0 3.132.809 5.972 11.179 13.865 10.382-7.911 11.217-10.854 11.217-13.865a5.557 5.557 0 0 0-5.262-5.8 5.4 5.4 0 0 0-5.262 4.2.659.659 0 0 1-.634.515.611.611 0 0 1-.65-.5c-.043-.171-1.124-4.218-5.328-4.218z" /></defs><g fill="none" fillRule="evenodd"><mask id="wishlist-32_svg__b" fill="#fff"><use xlinkHref="#wishlist-32_svg__a" /></mask><g fill="#000" mask="url(#wishlist-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;