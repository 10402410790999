import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="service-32_svg__a" d="M26.667 4a.666.666 0 0 1 .667.667v18.667a.666.666 0 0 1-.667.666H14.891l-5.156 3.868a.665.665 0 0 1-1.066-.533V24H5.334a.666.666 0 0 1-.667-.667V4.667A.666.666 0 0 1 5.334 4zM26 5.334H6v17.333h3.335c.368 0 .667.299.667.667V26l4.265-3.2a.667.667 0 0 1 .4-.133H26zM16 12c.368 0 .667.299.667.667v6.667a.667.667 0 0 1-1.334 0v-6.668c0-.368.299-.666.667-.666m0-3.666a1 1 0 1 1 0 2.002 1 1 0 0 1 0-2.003z" /></defs><g fill="none" fillRule="evenodd"><mask id="service-32_svg__b" fill="#fff"><use xlinkHref="#service-32_svg__a" /></mask><g fill="#000" mask="url(#service-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;