import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAppIcon = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" sx={{
    ...sx
  }} {...other}><g fill="none" transform="translate(716.999 687.29)"><mask id="app-icon_svg__a" fill="#fff"><use /></mask><path fill="#FDC300" d="M82.695.385c22.399 0 42.302 8.588 57.424 23.423 15.51 15.044 24.5 35.237 24.5 58.263 0 23.036-8.99 43.417-24.5 58.65-15.311 14.85-35.21 23.606-57.424 23.606-22.2 0-42.303-8.563-57.42-23.607C9.782 125.488.792 105.107.792 82.07c0-22.842 8.99-43.218 24.485-58.262C40.392 8.973 60.3.385 82.695.385" mask="url(#app-icon_svg__a)" /><mask id="app-icon_svg__b" fill="#fff"><use /></mask><path fill="#7E7975" d="M-3.092-106.975c0-41.484-7.377-78.697-22.463-111.816-15.08-33.12-34.8-60.987-59.321-83.779-24.508-22.798-52.29-40.173-83.31-52.136-31.05-11.975-62.768-18.037-94.82-18.037-26.577 0-50.752 3.445-72.863 10.487-22.123 7.048-42.004 16.24-59.834 27.548-17.841 11.484-36.07 28.17-48.056 42.157-1.527 1.785-3.214 3.94-4.938 6.227V-487H-518v623.455h69.303V67.71c2.242 3.342 4.49 6.69 6.965 10.06 10.28 13.981 23.459 28.836 44.8 44.33 16.626 12.065 35.416 22.934 60.55 30.61 22.26 6.802 46.799 10.001 73.376 10.001 32.052 0 63.77-6.073 94.82-18.532 31.02-12.3 58.802-30 83.31-53.118 24.52-23.111 44.24-51.144 59.32-84.257C-10.623-26.321-3.091-63.54-3.091-105.013zm-64.02-1.779c0 110.403-87.085 199.907-194.516 199.907-107.426 0-194.51-89.504-194.51-199.907 0-110.408 87.084-199.912 194.51-199.912 107.43 0 194.516 89.504 194.516 199.912" mask="url(#app-icon_svg__b)" /></g></SvgIcon>;
};
const Memo = memo(SvgAppIcon);
export default Memo;