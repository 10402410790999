import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgTruck32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="truck-32_svg__a" d="M20.153 6.746a.653.653 0 0 1 .64.788L18.33 19.251a.686.686 0 0 1-.027.09c.236-.06.478-.09.721-.091.176 0 .35.017.523.051l1.86-8.844a.653.653 0 0 1 .639-.519h.512c1.4 0 3.378.078 4.581 1.56l.143.189c.879 1.24 1.046 3.09.51 5.642l-.99 4.712a.653.653 0 0 1-.639.52h-4.827a3.54 3.54 0 0 1-3.256 2.485 2.411 2.411 0 0 1-1.917-.872 2.469 2.469 0 0 1-.529-1.609h-3.142a3.539 3.539 0 0 1-3.256 2.481 2.411 2.411 0 0 1-1.917-.872 2.469 2.469 0 0 1-.529-1.609H4.849a.654.654 0 0 1 0-1.308h2.264a3.643 3.643 0 0 1 1.337-1.485H3.921a.653.653 0 0 1-.64-.787l2.463-11.72a.653.653 0 0 1 .639-.519zm-1.129 13.813a2.253 2.253 0 0 0-2.051 1.724c-.099.367-.025.759.2 1.065.225.26.556.403.9.389a2.25 2.25 0 0 0 2.051-1.723c.099-.367.025-.76-.2-1.066a1.13 1.13 0 0 0-.9-.389m-8.845 0a2.253 2.253 0 0 0-2.051 1.724c-.099.367-.025.76.2 1.066.225.26.556.403.9.389a2.253 2.253 0 0 0 2.051-1.724c.099-.367.025-.76-.2-1.066a1.13 1.13 0 0 0-.9-.39zm7.116-.786h-5.557a2.3 2.3 0 0 1 .358.353c.261.327.432.718.494 1.132h3.367c.3-.61.763-1.124 1.338-1.485m5.281-8.527-1.823 8.674c.067.063.13.13.188.2.261.328.431.718.494 1.132h4.2l.881-4.192c.471-2.245.339-3.84-.392-4.741-.82-1.006-2.349-1.073-3.548-1.073m-3.228-3.191H6.915L4.726 18.464h12.435zm4.043 4.418c3.15-.213 2.275 3.408 2.275 3.408h-2.991z" /></defs><g fill="none" fillRule="evenodd"><mask id="truck-32_svg__b" fill="#fff"><use xlinkHref="#truck-32_svg__a" /></mask><g fill="#000" mask="url(#truck-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgTruck32);
export default Memo;