import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSuccessCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="success-circle-32_svg__a" d="M16.464 4.116c6.82 0 12.348 5.528 12.348 12.348 0 6.82-5.528 12.348-12.348 12.348-6.82 0-12.348-5.528-12.348-12.348.008-6.816 5.532-12.34 12.348-12.348m0 1.372a10.977 10.977 0 1 0 10.977 10.976c-.007-6.06-4.918-10.97-10.977-10.976m7.662 5.583c.257.257.27.67.028.942l-9.873 9.873a.684.684 0 0 1-.485.2.682.682 0 0 1-.485-.2l-4.535-4.533a.687.687 0 0 1 .97-.97l4.048 4.048 9.389-9.389a.687.687 0 0 1 .943.029" /></defs><g fill="none" fillRule="evenodd"><mask id="success-circle-32_svg__b" fill="#fff"><use xlinkHref="#success-circle-32_svg__a" /></mask><g fill="#7DAE2E" mask="url(#success-circle-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgSuccessCircle32);
export default Memo;