import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgInformationCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="information-circle-32_svg__a" d="M15.829 3.957c6.557 0 11.872 5.315 11.872 11.872 0 6.557-5.315 11.872-11.872 11.872-6.557 0-11.872-5.315-11.872-11.872.007-6.554 5.318-11.865 11.872-11.872m0 1.32A10.553 10.553 0 1 0 26.381 15.83c-.007-5.825-4.727-10.546-10.552-10.553m0 7.291a.66.66 0 0 1 .66.66v9.229a.66.66 0 0 1-1.32 0v-9.229a.66.66 0 0 1 .66-.66m0-4.284a.989.989 0 1 1 0 1.978.989.989 0 0 1 0-1.978" /></defs><g fill="none" fillRule="evenodd"><mask id="information-circle-32_svg__b" fill="#fff"><use xlinkHref="#information-circle-32_svg__a" /></mask><g fill="#8B969C" mask="url(#information-circle-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgInformationCircle32);
export default Memo;