import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgBurgerText32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="burger-text-32_svg__a" d="M5.516 21.979h.974l1.443 4.245 1.432-4.245h.964V27h-.646v-3.119l.006-.354c.005-.237.007-.49.007-.762L8.264 27h-.673l-1.443-4.235.009.716.009.556V27h-.65zm5.913 0h3.66v.615h-2.997v1.525h2.772v.58h-2.772v1.703h3.05V27h-3.713zm4.604 0h.803l2.537 4.067V21.98h.646V27h-.763l-2.573-4.064V27h-.65zm5.794 0v3.104c0 .364.068.667.205.909.203.364.544.547 1.025.547.577 0 .969-.198 1.176-.592.112-.214.167-.502.167-.864v-3.104h.69v2.82c0 .617-.082 1.092-.249 1.425-.305.606-.882.91-1.73.91-.847 0-1.422-.304-1.725-.91-.167-.333-.25-.808-.25-1.425v-2.82zm4.517-13.442H5.269a.659.659 0 1 1 0-1.317h21.075a.659.659 0 0 1 0 1.317m0 5.274H5.269a.659.659 0 1 1 0-1.317h21.075a.659.659 0 0 1 .094 1.307zm0 5.266H5.269a.659.659 0 1 1 0-1.317h21.075a.659.659 0 0 1 0 1.317" /></defs><g fill="none" fillRule="evenodd"><mask id="burger-text-32_svg__b" fill="#fff"><use xlinkHref="#burger-text-32_svg__a" /></mask><g fill="#000" mask="url(#burger-text-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgBurgerText32);
export default Memo;