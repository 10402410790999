import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgNewsletter32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="newsletter-32_svg__a" d="M14.337 3.273a2.656 2.656 0 0 1 3.383.005l4.02 3.389h4.265c.368 0 .665.298.666.666v5.268l1.029.872c.19.118.307.324.31.548v14.646a.666.666 0 0 1-.667.667H4.672a.667.667 0 0 1-.667-.667V14.022c0-.01-.005-.018-.005-.028a.647.647 0 0 1 .054-.218v-.017a.644.644 0 0 1 .107-.159c.016-.018.027-.04.044-.057l.018-.024 1.1-.962V7.335A.666.666 0 0 1 6 6.667h4.285zm4.449 19.221-.906.816c-.523.45-1.19.699-1.88.7a3.228 3.228 0 0 1-1.941-.759l-.82-.72L6.439 28h19.082zm7.886-7.049a911.032 911.032 0 0 0-6.885 6.147l6.885 5.628zm-21.335.1v11.628l6.885-5.536c-2.014-1.77-5.172-4.553-6.885-6.094zm20-7.542H6.661v6.941c2.733 2.435 5.478 4.86 8.235 7.273a1.9 1.9 0 0 0 1.1.462c.366 0 .72-.13 1-.367.173-.157 2.309-2.084 4.56-4.1l.812-.727a627.037 627.037 0 0 1 2.963-2.636zm-4.67 6.664a.667.667 0 0 1 0 1.334h-9.324a.667.667 0 0 1 0-1.334zm-.01-4.009a.667.667 0 0 1 0 1.334h-9.323a.667.667 0 0 1 0-1.334zm-3.8-6.365a1.351 1.351 0 0 0-1.658 0l-2.837 2.374h7.31z" /></defs><g fill="none" fillRule="evenodd"><mask id="newsletter-32_svg__b" fill="#fff"><use xlinkHref="#newsletter-32_svg__a" /></mask><g fill="#000" mask="url(#newsletter-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgNewsletter32);
export default Memo;