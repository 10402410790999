import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWarningCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="warning-circle-32_svg__a" d="M16.535 2.657 30.41 29.226H2.657zm0 2.856L4.84 27.903h23.388zm0 17.405a1 1 0 1 1-.001.001zm0-12.288c.367 0 .664.297.664.664v8.636a.665.665 0 0 1-1.329 0v-8.635c0-.367.297-.664.664-.664z" /></defs><g fill="none" fillRule="evenodd"><mask id="warning-circle-32_svg__b" fill="#fff"><use xlinkHref="#warning-circle-32_svg__a" /></mask><g fill="#F5A623" mask="url(#warning-circle-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgWarningCircle32);
export default Memo;